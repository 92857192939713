function init() {
	console.log('Started'); // eslint-disable-line no-console
	issue();
	copyright();
}

const issue = () => {
	Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const container = document.getElementById('js-issue-evergreen');

	const month = document.createElement('span');
	month.innerHTML = Date.shortMonths[new Date().getMonth()];
	container.appendChild(month);

	const year = document.createElement('span');
	year.innerHTML = new Date().getFullYear();
	container.appendChild(year);
};

const copyright = () => {
	const copyright = document.getElementById('js-copyright');
	copyright.innerHTML = new Date().getFullYear();
};

init();
